import React, { Component } from "react";
import "./Authentication.css";
import { Redirect } from "react-router-dom";
import LoadingPage from "../../components/Loading/Loading";
import config from "../../config";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      rememberMe: false,
      invalidLoginTriggered: false,
      redirect: false,
    };
  }

  validateForm() {
    if (this.state.email.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide a email.",
      });
    } else if (this.state.password.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide a password.",
      });
    } else {
      return true;
    }

    return false; //If not in else, then it's failed
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };


  handleSubmit = async (event) => {
    event.preventDefault();

    const formValidated = this.validateForm();

    if (formValidated) {
      this.setState({ isLoading: true });

      let url = config.backendServer + "login/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: this.state.password,
          email: this.state.email,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 400) {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "You have entered an invalid username or password",
              email: "",
              password: "",
            });
          } else if (response.status === 403) {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "Email is not verified.",
              email: "",
              password: "",
            });
          } else {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "Problem with system. Please refresh and try again later.",
              email: "",
              password: "",
            });
          }
        })
        .then((response) => {
          let roleUser = response && JSON.parse(response).user.role;
          if (response && roleUser !== "Customer") {
            // All fails should be handled in above function
            this.props.onLogin(response, this.state.rememberMe, () => {
              this.setState({ redirect: true, isLoading: false });
            });
          } else {
            this.setState({
              isLoading: false,
            });
            !this.state.invalidLoginTriggered &&
              this.setState({
                invalidLoginTriggered: `${this.state.email} is a ${roleUser}, not allow to access admin page`,
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            isLoading: false,
          });
          !this.state.invalidLoginTriggered &&
            this.setState({
              invalidLoginTriggered: `Internal Server Error, system encountered an unexpected problem and is being tracked.`,
            });
        });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    } else if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div>
        <h2 className={"auth-page-header"}>Login</h2>
        {this.state.invalidLoginTriggered && <div className={"incorrectAuthenticationText"}>{this.state.invalidLoginTriggered}</div>}
        <form aria-label="login-form" onSubmit={this.handleSubmit}>
          <div className="auth-form-field">
            <label>Email</label>
            <input data-testid="email" type={"text"} required={true} id={"email"} value={this.state.email} onChange={this.handleChange} />
          </div>
          <div className="auth-form-field">
            <label>Password</label>
            <input
              data-testid="password"
              type={"password"}
              required={true}
              id={"password"}
              value={this.state.password}
              onChange={this.handleChange}
            />
          </div>
          <button className="k-btn k-btn-primary-full" type="submit">
            Login
          </button>
        </form>
      </div>
    );
  }
}
